@font-face {
  font-family: "DrukWide";
  font-weight: 700;
  src: local("DrukWide"),
    url(./assets/fonts/DrukWide-Medium.woff2) format("woff");
}

@font-face {
  font-family: "icomoon";
  font-weight: normal;
  font-display: block;
  font-style: normal;
  src: local("icomoon"), url(./assets/fonts/icomoon.woff) format("woff");
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?gja2k7");
  src: url("./assets/fonts/icomoon.eot?gja2k7#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?gja2k7") format("truetype"),
    url("./assets/fonts/icomoon.woff?gja2k7") format("woff"),
    url("./assets/fonts/icomoon.svg?gja2k7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.App {
  font-weight: "bold";
  /*background-color: #161618;*/
  color: #fff;
}

::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: #ffffff;
  background: #2962ff;
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
}

.link-underline:hover {
  background-size: 100% 2px;
  background-position: 0 100%;
}

.gradient {
  background: #4389a2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    315deg,
    #5c258d,
    #734b6d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    315deg,
    #5c258d,
    #734b6d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.black-gradient {
  background: #4389a2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    180deg,
    transparent,
    black
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(180deg, transparent, black);
}

.custom-font {
  font-family: "DrukWide";
  line-height: 1;
}

.header {
  color: #ffffff;
}

.header::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  mix-blend-mode: difference;
}

.header-blend {
  mix-blend-mode: difference;
}

.footer {
  background-color: #000000 !important;
  padding: 50px;
}

.gradient-background {
  background: linear-gradient(
    300deg,
    #1976d2,
    #7e57c2,
    #673ab7,
    #6200ea,
    #2962ff,
    #2979ff
  );
  background-size: 180% 180%;
  animation: gradient-animation 8s ease infinite;
}

.text-mask {
  background-clip: text;
  color: transparent !important;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.text-size-full {
  font-size: 7.85vw !important;
  transition: 0.2s ease;
}

@media screen and (min-width: 414px) {
  .text-size-full {
    font-size: 7.5vw !important;
    transition: 0.2s ease;
  }
}

@media screen and (min-width: 768px) {
  .text-size-full {
    font-size: 6vw !important;
    transition: 0.2s ease;
  }
}

@media screen and (min-width: 1024px) {
  .text-size-full {
    font-size: 80px !important;
    transition: 0.2s ease;
  }
}

@media screen and (min-width: 1280px) {
  .text-size-full {
    font-size: 96px !important;
    transition: 0.2s ease;
  }
}

.logo-container {
  position: relative;
  width: 28px;
  height: 28px;
}

.logo-bar-1 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 4px;
  transform: rotate(-15deg) skew(165deg);
  border-top-left-radius: 2px;
}

.logo-bar-2 {
  position: absolute;
  top: 8px;
  left: 0;
  background-color: #ffffff;
  width: 65%;
  height: 4px;
  transform: rotate(165deg) skew(165deg);
  border-top-left-radius: 2px;
}

.logo-bar-3 {
  position: absolute;
  top: 14px;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 4px;
  transform: rotate(165deg) skew(165deg);
  border-top-left-radius: 2px;
}

.layout-max {
  max-width: 2560px;
  transition: 0.2s ease;
}

.hero-container {
  max-width: 1200px;
}

.header-black-text {
  color: #000000 !important;
}

/* PARALLAX */

.marquee-section {
  height: 100vh;
  overflow: hidden;
}

.contact-marquee-cont {
  position: absolute;
  width: 100%;
  height: calc(100vh + 120px);
  top: -120px;
  display: flex;
  flex-direction: column;
}


.marquee-text-size {
  font-size: 40vh;
  color: rgba(0, 0, 0, 0.03);
  overflow: hidden;
  height: 100%;
  user-select: none;
}

/* HEADER MENU **/
.header-links-wrapper ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
}

.header-links-wrapper ol {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
}

.header-link {
  color: #999999;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}

.header-link:hover {
  color: #ffffff;

  transition: 0.2s ease;
}

.header-link:active {
  color: #ffffff;

  transition: 0.2s ease;
}

.header-link-a a {
  color: #ff00cc;
}

.header-link-a :hover {
  color: #ff00cc !important;
}

.header-link-underline {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  background-color: #ffffff;
}

.logo-name {
  user-select: none;
}

.profile-picture-container {
  position: absolute;
  left: 70px;
  top: 104px;
  z-index: 10;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .profile-picture-container {
    position: absolute;
    left: 20px;
    top: 24px;
    z-index: 10;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    overflow: hidden;
  }
}

.profile-picture {
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  mix-blend-mode: normal !important;
  z-index: 1000;
  overflow: hidden;
}

.header-isolate {
  isolation: isolate;
}

.text-color-to-right {
  background: linear-gradient(
      300deg,
      #1976d2,
      #7e57c2,
      #673ab7,
      #6200ea,
      #2962ff,
      #2979ff
    )
    white no-repeat 0 0;
  background-size: 0 100%;
  animation: stripes 0.2s linear 1 forwards;
  color: transparent;
  background-clip: text;
}

@keyframes stripes {
  to {
    background-size: 100% 100%;
  }
}

.photography-text-1 {
  opacity: 0.2;
  background-image: url("../public/img/contact/window.jpg");
  background-size: cover;
  background-position: center;
}

.photography-text-2 {
  opacity: 0.2;
  background-image: url("../public/img/contact/roof.jpg");
  background-size: cover;
  background-position: center;
}

.photography-text-3 {
  opacity: 0.2;
  background-image: url("../public/img/contact/brook.jpg");
  background-size: cover;
  background-position: center;
}

.photography-text-4 {
  opacity: 0.2;
  background-image: url("../public/img/contact/grass.jpg");
  background-size: cover;
  background-position: center;
}

.photography-text-5 {
  opacity: 0.2;
  background-image: url("../public/img/contact/lights.jpg");
  background-size: cover;
  background-position: center;
}

.photography-text-6 {
  opacity: 0.2;
  background-image: url("../public/img/contact/colors.jpg");
  background-size: cover;
  background-position: center;
}


[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-elogo:before {
  content: "\e900";
}
.icon-elogo-rounded:before {
  content: "\e901";
}
.icon-arrow-link:before {
  content: "\e902";
}

.port-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #673ab7, #2979ff 50%, #100f0f 50%);
  transition: background-position 0.25s;
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 100% top, 100% top;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.port-link:hover {
  background-position: 0% top, 0% top;
  width: 100%;
}

.port-link::after {
  position: absolute;
  content: "";
  background: black;
  bottom: 0;
  right: 0;
  height: 1.5px;
  width: 100%;
  transition: 0.25s ease-in-out;
}

.port-link:hover::after{
  background-image: linear-gradient(to right, #673ab7, #2979ff 50%, #100f0f 50%);
  background-size: 200% 100%;
  width: 100%;
  left: 0;
}

.contact-background {
  background-color: #ffffff;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.description-container {
  width: 100%;
}